export const fadeIn = ({ delay, duration, }) => ({
    hidden: {
        opacity: 0,
        scale: 0.9,
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: delay || 0,
            duration,
            ease: 'easeIn',
        },
    },
});
export const flyIn = ({ direction, delay, duration, }) => ({
    hidden: {
        y: direction === 'down' ? -100 : direction === 'up' ? 100 : 0,
        x: direction === 'right' ? -100 : direction === 'left' ? 100 : 0,
        opacity: 0,
    },
    visible: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            delay: delay || 0,
            duration,
            ease: 'easeIn',
        },
    },
});
export const floating = ({ axis, duration, delay, offset, }) => ({
    floating: {
        delay: delay || 0,
        [axis]: [0, offset || 10, 0],
        transition: {
            duration: duration || 1,
            repeat: Infinity,
            repeatType: 'mirror',
        },
    },
});
export const marquee = ({ offset, duration }) => ({
    animate: {
        x: [0, offset || 100],
        transition: {
            x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: duration || 1,
                ease: 'linear',
            },
        },
    },
});
